import React from 'react';
import {View} from 'react-native';
import Result from './result/result';
import Winner from './winner/winner';
import Analyze from './analyze/analyze';
import OrderBetsList from './order/order';
import {useResponsiveDimensions} from '@/utils';

const DiceTabView = ({
  refreshCode,
  configId,
  index,
}: {
  refreshCode: number;
  configId?: number;
  index: number;
}) => {
  const [showMap] = React.useState([true, false, false, false]);
  React.useEffect(() => {
    showMap[index] = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);
  const {height: screenHeight} = useResponsiveDimensions();
  return (
    <View
      style={[
        {
          minHeight: screenHeight - 100,
        },
      ]}>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: index === 0 ? '100%' : 0, overflow: 'hidden'},
        ]}>
        {showMap[0] ? (
          <Result
            refreshCode={refreshCode}
            configId={configId}
            isActive={index === 0}
          />
        ) : null}
      </View>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: index === 1 ? '100%' : 0, overflow: 'hidden'},
        ]}>
        {showMap[1] ? <Winner /> : null}
      </View>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: index === 2 ? '100%' : 0, overflow: 'hidden'},
        ]}>
        {showMap[2] ? (
          <Analyze
            refreshCode={refreshCode}
            configId={configId}
            isActive={index === 2}
          />
        ) : null}
      </View>
      {showMap[3] ? (
        <OrderBetsList refreshCode={refreshCode} isActive={index === 3} />
      ) : null}
    </View>
  );
};

export default DiceTabView;
