/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View, Image} from 'react-native';
import {DICES} from '../../constant';
import DiceItem from '../../component/dice-item';
import Ball from '../../component/ball';
import {TypeListItem} from '../../dice-service';
import Text from '@/components/basic/text';
import Price from '@/components/business/price';
import OrderListItemStatus from '@/components/business/order/order-list-item-status';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import FeeDetail from '@/components/business/order/order-fee-detail';
const OrderBetsListItem = ({
  item,
  index = 0,
  status = 2,
  betsTime,
}: {
  item: TypeListItem;
  index?: number;
  status: 0 | 1 | 2;
  betsTime?: string;
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? theme.basicColor.white : '#F8F9FF',
    };
  }, [index]);

  const NumberNode = React.useMemo(() => {
    const toNumber = Number(item.number);
    let numberArr = [];
    switch (item.playType) {
      case 'Single':
        return <DiceItem diceSize={28} diceNum={toNumber} />;
      case 'Sum':
        return (
          <Ball
            ballSize={28}
            num={toNumber}
            fontSize={16}
            isNum={!isNaN(toNumber)}
            type={item?.number?.toLowerCase()}
          />
        );
      case 'Pair':
        numberArr = item.number.split('');
        return (
          <View style={[theme.flex.row]}>
            {numberArr.map((pie: string, i: number) => (
              <DiceItem key={i} diceSize={20} diceNum={Number(pie)} />
            ))}
          </View>
        );
      case 'Triple':
        if (isNaN(toNumber)) {
          return (
            <View style={[theme.flex.row]}>
              <View style={[theme.flex.centerByCol]}>
                <Image source={DICES.any} style={{width: 20, height: 20}} />
                <View style={[theme.flex.row]}>
                  {['any', 'any'].map((pie: string, i: number) => (
                    <Image
                      key={i}
                      source={DICES[pie]}
                      style={{width: 20, height: 20}}
                    />
                  ))}
                </View>
              </View>
            </View>
          );
        } else {
          numberArr = item.number.split('');
          return (
            <View style={[theme.flex.row]}>
              <View style={[theme.flex.centerByCol]}>
                <DiceItem diceSize={20} diceNum={Number(numberArr[0])} />
                <View style={[theme.flex.row]}>
                  {numberArr.slice(1).map((pie: string, i: number) => (
                    <DiceItem key={i} diceNum={Number(pie)} diceSize={20} />
                  ))}
                </View>
              </View>
            </View>
          );
        }
    }
  }, [item]);

  return (
    <View style={[backgroundColor]}>
      <NativeTouchableOpacity
        onPress={() => setShowMore(!showMore)}
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.padding.l,
          {columnGap: 8},
        ]}>
        <View style={[{width: 52}]}>{NumberNode}</View>
        <View style={[{width: 72}]}>
          <Text textAlign="center">
            {item.playType === 'Pair' ? 'Double' : item.playType}
          </Text>
        </View>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Price
            price={item.amount}
            textProps={{
              size: 'medium',
            }}
          />
        </View>
        <View style={[theme.flex.flex1, theme.flex.alignEnd]}>
          <OrderListItemStatus
            hasDetail
            showMore={showMore}
            status={item.awardAmount > 0 ? 1 : status === 2 ? 2 : 0}
            awardAmount={item.awardAmount}
          />
        </View>
      </NativeTouchableOpacity>
      {showMore && (
        <FeeDetail
          delivery={item.basePrice}
          fee={item.amount - item.basePrice}
          time={betsTime}
        />
      )}
    </View>
  );
};

export default OrderBetsListItem;
